import React from 'react';
import Navbar from './Navbar';
import GoogleMaps from '../Components/GoogleMaps';
import "./contact.css";
import ContactForm from '../Components/ContactForm';
import Footer from '../Components/Footer';
import AddressBar from '../Components/AddressBar';

const Contact = () => {

    return (
        <>
            <head>
                <base href="/" />
                <script src="https://kit.fontawesome.com/3b51e1b9c2.js" crossOrigin="anonymous"></script>
                <link href="https://fonts.googleapis.com/css2?family=Catamaran:wght@200&display=swap" rel="stylesheet"></link>
            </head>
            <AddressBar />
            <div className="d-flex justify-content-center">
                <div className="container-md mx-2 mb-4">
                    <Navbar />
                    <h1 className='text-center'>Contact Us</h1>
                    <div className="row mt-5 mx-2 add">
                        <div className="col-md-6 maps">
                        
                        </div>
                        <div className="col-md-6 info">
                            <table className='store-info'>
                                <tbody>
                                    <tr>
                                        <th>Store:</th>
                                        <td>#115 - 6660 Graybar Road Richmond BC V6W 1H9</td>
                                    </tr>
                                    <tr>
                                        <th>Tel:</th>
                                        <td>778-877-7217</td>
                                    </tr>
                                    <tr>
                                        <th>Email:</th>
                                        <td>info@hrzfloors.com</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 className='store-hours'>Store Hours</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Monday:</th>
                                        <td>9:00AM - 5:00PM</td>
                                    </tr>
                                    <tr>
                                        <th>Tuesday:</th>
                                        <td>9:00AM - 5:00PM</td>
                                    </tr>
                                    <tr>
                                        <th>Wednesday:</th>
                                        <td>9:00AM - 5:00PM</td>
                                    </tr>
                                    <tr>
                                        <th>Thursday:</th>
                                        <td>9:00AM - 5:00PM</td>
                                    </tr>
                                    <tr>
                                        <th>Friday:</th>
                                        <td>9:00AM - 5:00PM</td>
                                    </tr>
                                </tbody>
                            </table>

                            
                        </div>
                    </div>
                    <div className="mt-5">
                        <ContactForm />
                    </div>

                </div>
            </div>
            <Footer />
        </>

    )
}

export default Contact;

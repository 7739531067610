import "babel-polyfill";
import React from 'react';
import App from './App';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Category from './pages/Category';
import Product from './pages/Product';
import Products from './Components/Product/Products';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import ScrollToRop from './Components/ScrollToTop';
import Laminate from './pages/Laminate';
import Hardwood from './pages/Hardwood';
import Accessory from './pages/Accessory';
import Return from './Components/Return';
import { hydrate, render } from "react-dom";



const APPSNAP = (
<BrowserRouter>
    <ScrollToRop />
      <Routes>
        <Route path='/' element={<App />} >
          <Route index element={<Home />} />
          <Route path='/vinyl' element={<Category />} />
          <Route path='/laminate' element={<Laminate />} />
          <Route path='/hardwood' element={<Hardwood />} />
          <Route path='/accessory' element={<Accessory />} />
          <Route path='/return' element={<Return />} />
          <Route 
          path='*' 
          element={
            <main style={{ padding: "1rem" }}>
              <p>Page not found.</p>
            </main>
          }
          />
          
        </Route>
        {/* <Route path='/search' element={<SearchPage />}>
          <Route path=':keywords' element={<Search />} />
        </Route> */}
        
        {/* <Route path='/products' element={<Category />} /> */}
        <Route path='/products' element={<Products />}>
          <Route exact path=':productSku' element={<Product />} />
        </Route>
        <Route path='/contact' element={<Contact />} />
        <Route path='/blog' element={<Blog />}/>
      </Routes>
    
  </BrowserRouter>
)



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APPSNAP, rootElement);
} else {
  render(APPSNAP, rootElement);
}




// =============================== working without react-snap ================================



// root.render(

//   <BrowserRouter>
//     <ScrollToRop />
//       <Routes>
//         <Route path='/' element={<App />} >
//           <Route index element={<Home />} />
//           <Route path='/vinyl' element={<Category />} />
//           <Route path='/laminate' element={<Laminate />} />
//           <Route path='/hardwood' element={<Hardwood />} />
//           <Route path='/accessory' element={<Accessory />} />
//           <Route path='/return' element={<Return />} />
          
//         </Route>
//         {/* <Route path='/search' element={<SearchPage />}>
//           <Route path=':keywords' element={<Search />} />
//         </Route> */}
        
//         {/* <Route path='/products' element={<Category />} /> */}
//         <Route path='/products' element={<Products />}>
//           <Route path=':productSku' element={<Product />} />
//         </Route>
//         <Route path='/contact' element={<Contact />} />
//         <Route path='/blog' element={<Blog />}/>
//       </Routes>
    
//   </BrowserRouter>



// );
// =============================== working without react-snap ================================
import React from 'react';
import { TextField, Button } from '@mui/material';


const SearchForm = ({ searchTerm, searchedTerm, inputValue }) => {
    return (

        <div className='col-md-4 mb-3 ms-auto'>
            <form 
            onSubmit={searchTerm}
            >
                <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    value={searchedTerm}
                    onChange={inputValue}
                />
                <Button className='m-2' variant="contained" type="submit">Go</Button>
            </form>

        </div>



        // <div className='col-md-4 mb-3 ms-auto'>

        //         <TextField 
        //         id="outlined-basic" 
        //         label="Search" 
        //         variant="outlined" 
        //         value={searchedTerm.get("search") || ""}  
        //         onChange={searchTerm}
        //         />


        // </div>
    )
}

export default SearchForm;
import React from 'react';

const TabSpec = ({ thickness, width, wearlayer, acrating, underlayment, installation, packaging, country, warranty }) => {

  return (
    <table className='table'>
      <tbody >
        {thickness ?
          <tr>
            <th scope='row'>Thickness:</th>
            <td>{thickness}</td>
          </tr> :
          ""}


        {width
          ?
          <tr>
            <th scope='row'>Width:</th>
            <td>{width}</td>
          </tr>
          :
          ""}



        {wearlayer ? <tr>
          <th scope='row'>Wear Layer:</th>
          <td>{wearlayer}</td>
        </tr>
          :
          ""
        }

        {acrating ? <tr>
          <th scope='row'>AC (Durability) Rating:</th>
          <td>{acrating}</td>
        </tr>
          :
          ""
        }

        {underlayment ?
          <tr>
            <th scope='row'>Underlayment:</th>
            <td>{underlayment}</td>
          </tr> :
          ""}



        {installation ?
          <tr>
            <th scope='row'>Installation Method:</th>
            <td>{installation}</td>
          </tr> :
          ""}

        {packaging ?
          <tr>
            <th scope='row'>Package:</th>
            <td>{packaging}</td>
          </tr> :
          ""}

        {country ?
          <tr>
            <th scope='row'>Country of Origin:</th>
            <td>{country}</td>
          </tr> :
          ""}

        {warranty ? <tr>
          <th scope='row'>Warranty Information:</th>
          <td>{warranty}</td>
        </tr>
          :
          ""
        }


      </tbody>
    </table>
  )
}

export default TabSpec
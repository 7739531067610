import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getProduct } from '../constants/Data';
import './boxCalculator.css';


const BoxCalculator = () => {

  const [sqftNeeded, setSqftNeeded] = useState('');
  const [boxesNeeded, setBoxesNeeded] = useState('');

  let params = useParams();
  let product = getProduct(params.productSku);

  var regex = /[\d|,|.|e|E|\+]+/g;

  var sqftPerBox = Number(product.package.match(regex));



  const increment = () => {
    setBoxesNeeded(Number(boxesNeeded)+1);
    setSqftNeeded(parseFloat(Number(sqftNeeded)+sqftPerBox).toFixed(2));
    console.log(boxesNeeded);
    console.log(sqftNeeded);
  }
  const decrement = () => {
    setBoxesNeeded(boxesNeeded-1);
    setSqftNeeded(parseFloat(sqftNeeded-sqftPerBox).toFixed(2));
    
  }

  return (
    <div className='mx-4'>

      <table className='calculator-table'>
        <tbody>
          <tr>
            <th><h3>Floor Amount Calculator</h3></th>
          </tr>
          <tr className='input-row d-flex justify-content-between'>
            <td><TextField size='small' variant='outlined' label='SQFT Needed' type="number" value={sqftNeeded} onInput={e => {
              setSqftNeeded(Number(e.target.value));
              setBoxesNeeded(Number(e.target.value / sqftPerBox))
            }} InputLabelProps={{ shrink: true }} 
            /></td>
            <td className='d-flex '>
              <Button style={{ maxWidth: '40px', minWidth: '40px', }} className='box-button' size='small' variant="contained" onClick={()=>decrement()}>-</Button>
              <TextField id='boxes-needed' size='small' variant='outlined' label='# Boxes Needed' type="number" value=
                {
                  Math.round(Number(boxesNeeded))
                } InputLabelProps={{ shrink: true }} 
                />
              <Button style={{ maxWidth: '40px', minWidth: '40px', }} className='box-button' size='small' variant="contained" onClick={()=>increment()}>+</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BoxCalculator
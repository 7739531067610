import React from "react";
import { Link } from "react-router-dom";
import "./home.css";

const Home = () => {
  const clearLocal = () => {
    localStorage.clear();
  };

  return (
    <>
      <div className="container-md banner">
        <div className="row">
          
          <div className="col-md-6 offset-md-3 title">
            <h2 className="text-center">
            <div>
        <img src="/images/SalesEventBrush.png" height="300" alt="Sales Event Image" />
        
      </div>
            </h2>

            {/* <h3 className="text-center">
              <b>Simple Website, Easy Process</b>
            </h3> */}
            <p className="text-center">
              {" "}
              We do <b>free Estimates</b> <a href="tel:778-877-7217">call us</a>{" "}
              or {" "}<a href="mailto: info@hrzfloors.com">email us</a> to
              get <b>a free sample delivered</b> or make an order.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4 mt-4">
            <div className="card cate-card vinyl">
              <h1 className="text-center mt-5">
                <b>VINYL</b>
              </h1>
              <Link
                to="/vinyl"
                className="mx-auto mt-3 border px-2 subtitle"
                onClick={() => clearLocal()}
              >
                View Products
              </Link>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="card cate-card laminate">
              <h1 className="text-center mt-5">
                <b>LAMINATE</b>
              </h1>
              <Link
                to="/laminate"
                className="mx-auto mt-3 border px-2 subtitle"
                onClick={() => clearLocal()}
              >
                View Products
              </Link>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="card cate-card hardwood">
              <h1 className="text-center mt-5">
                <b>ENGINEERED HARDWOOD</b>
              </h1>
              <Link
                to="/hardwood"
                className="mx-auto mt-3 border px-2 subtitle"
                onClick={() => clearLocal()}
              >
                View Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import React from 'react'

const Return = () => {
  return (
    <div>
        <h1 className='text-center'>Returns & Refunds</h1>
        <h3><b>General</b></h3>
        <p>For majority of products purchased from our store, returns or refunds could happen within 30 days and with the present of original receipt or proof of purchase and original packing. </p>
        <h3><b>Exceptions</b></h3>
        <p>Clearance products are final sale</p>
        <p>Any custom produced items are not eligible for return</p>
    </div>
  )
}

export default Return
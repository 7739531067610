import React from 'react';
import Navbar from './Navbar';
import Footer from '../Components/Footer';
import AddressBar from '../Components/AddressBar';

const Blog = () => {
  return (<>
  <AddressBar />
    <div className="d-flex justify-content-center">
      <div className="container-md mx-2 mb-4">
        <Navbar />
        <div>Blog</div>
      </div>
    </div>
    <Footer />
  </>

  )
}

export default Blog
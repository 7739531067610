import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../pages/Navbar";
import Footer from "../Footer";
import AddressBar from "../AddressBar";

const Products = () => {
  return (
    <>
      <head>
        <base href="/" />
      </head>
      <AddressBar />
      <div className="container-md  mx-2">
        <Navbar />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Products;

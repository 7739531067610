import React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";

const Sort = ({ selectSort, selectedSort }) => {
  return (
    <div className="col-md-4 mb-3 ms-auto">
      <FormControl fullWidth>
        <InputLabel id="sort-label">Sort</InputLabel>
        <Select
          labelId="sort-label"
          id="sort-select"
          value={selectedSort}
          label="Sort"
          onChange={selectSort}
        >
          <MenuItem value="pricelowest">Price - Low To High</MenuItem>
          <MenuItem value="pricehighest">Price - High To Low</MenuItem>
        </Select>
      </FormControl>

      {/* <select defaultValue="Sort" id="sort" onChange={selectSort}>
            <option disabled value="Sort">Sort</option>
            <option value="pricehighest">Price - High to Low</option>
            <option value="pricelowest">Price - High to Low</option>
        </select> */}
    </div>
  );
};

export default Sort;

import React from "react";
import { Link } from "react-router-dom";
import "./listItem.css";

const ListItem = ({
  item: { title, desc, price, image, sku, isOnSale, newPrice },
}) => (
  <div className="col-md-4 mb-4">
    <Link
      className="text-reset text-decoration-none"
      to={`/products/${sku}`}
    >
      <div className="card">
        <img
          className="card-img-top px-2 pt-2"
          src={image[0]}
          alt="Card cap"
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          {isOnSale ? (
            <p>
              Price: <span className="priceCrossed">${price}</span>
              <span className="newPrice">${newPrice}</span>/SQFT
            </p>
          ) : price === 12345 ? (
            <p>Call For Pricing</p>
          ) : (
            <p>Price: ${price}/SQFT</p>
          )}

          <p className="card-text">{desc}</p>

          <button className="btn btn-dark">Details</button>
        </div>
      </div>
    </Link>
  </div>
);

export default ListItem;

// --------------------- Previous List ----------------------------

// const ProductList = () => {

//   return (
//       <>

//     <div className="col-md-9">

//             <div className="row">

//               {products.map((values) => {
//                 const { id, title, desc, price, image } = values;

//                 return (
//                   <>

//                     <div className="col-md-4 mb-4" key={id}>
//                       <div className="card" >

//                         <img className="card-img-top" src={image} alt="Card image cap" />
//                         <div className="card-body">
//                           <h5 className="card-title">{title}</h5>
//                           <p>Price: {price}</p>
//                           <p className="card-text">{desc}</p>

//                           <button className="btn btn-dark">Get Sample</button>
//                         </div>

//                       </div>

//                     </div>

//                   </>
//                 )
//               })}
//             </div>
//           </div>
//           </>
//   )
// }

// export default ProductList;

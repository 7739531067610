import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';



const BreadCrumb = ({ productCategory, productSku }) => {
    return (

        <Breadcrumbs aria-label="breadcrumb" className='mb-5'>
            <Link underline="hover" color="inherit" to="/">
                Home
            </Link>
            <Link underline="hover" color="inherit" to={{pathname: `/${productCategory}`}}>
                Products
            </Link>
            {productCategory ? 
            <Link
                underline="hover"
                color="inherit"
                to={{
                    pathname: `/${productCategory}`
                }}
                >
                {productCategory}
            </Link> : ""}


            {productSku ? <Typography color="text.primary">{productSku}</Typography> : ""}







        </Breadcrumbs>
    )
}

export default BreadCrumb;

import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {

    const clearLocal = () => {
        localStorage.clear();
    }

  return (
    <nav className="navbar navbar-expand-md navbar-light">
      <Link className="navbar-brand" to="/">
        <img src="/images/icon/HorizonLogo.png" alt="" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <div
        className="collapse navbar-collapse ms-auto"
        id="navbarSupportedContent"
      >
        <ul className="nav navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Products
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/vinyl" onClick={clearLocal}>
                Vinyl
              </Link>
              <Link className="dropdown-item" to="/laminate" onClick={clearLocal}>
                Laminate
              </Link>
              <Link className="dropdown-item" to="/hardwood" onClick={clearLocal}>
                Hardwood
              </Link>
              <Link className="dropdown-item" to="/accessory" onClick={clearLocal}>
                Accessory
              </Link>
            </div>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

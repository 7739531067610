import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { FormControl, FormControlLabel } from '@mui/material';

const RadioToggle = ({ options, value, selectToggle }) => {
    return (
        <FormControl>
            <RadioGroup 
                value={value}
                onChange={selectToggle}
            >

                {options.map(({ label, id, value }) => (
                    <FormControlLabel key={id} value={value} id={value} control={<Radio className="p-2" size='small' />} label={label} />

                    // <Radio key={id} value={value}>
                    //     {label}
                    // </Radio>
                ))}
            </RadioGroup>
        </FormControl>
    );
};
export default RadioToggle;
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Footer from "./Components/Footer";
import AddressBar from "./Components/AddressBar";
import Helmet from "react-helmet";

const App = () => {
  return (
    <>
      <Helmet>
        <title>Horizon Floors</title>
        <meta
          name="description"
          content="Shop Vinyl, Laminate and Engineered Hardwood Floors at Horizon Floors. Affordable price for high quality flooring."
        />
        <meta
          name="keywords"
          content="Vinyl, Laminate, Engineered Hardwood, Flooring, SPC, Underlayment, Horizon, Affordable"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <AddressBar />

      <div className="d-flex justify-content-center">
        <div className="container-md  mx-2">
          <Navbar />
          <div className="row mt-5 mx-2">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;

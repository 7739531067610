

export const categoryList = [
    {
        id: 1,
        value: 'vinyl',
        label: 'Vinyl',
    },
    {
        id: 2,
        value: 'laminate',
        label: 'Laminate',
    },
    {
        id: 3,
        value: 'hardwood',
        label: 'Hardwood',
    },
    {
        id: 4,
        value: 'accessory',
        label: 'Accessory',
    },
]

export const thicknessList = [
    {
        id: 1,
        value: '6.5mm',
        label: '6.5mm (for SPC only)'
    },
    {
        id: 2,
        value: '4.2mm',
        label: '4.2mm (for SPC only)'
    },
    {
        id: 3,
        value: '8mm',
        label: '8mm'
    },
    {
        id: 4,
        value: '12mm',
        label: '12mm'
    },
    {
        id: 5,
        value: '14mm',
        label: '14mm'
    }

]
export const thicknessHardwood = [

    {
        id: 1,
        value: '12mm',
        label: '12mm'
    },
    {
        id: 2,
        value: '14mm',
        label: '14mm'
    }

]
export const thicknessSpc = [
    {
        id: 1,
        value: '4.2mm',
        label: '4.2mm(3.2+1)'
    },
    {
        id: 2,
        value: '5mm',
        label: '5mm'
    },
    {
        id: 3,
        value: '6.5mm',
        label: '6.5mm(5+1.5)'
    },
    {
        id: 4,
        value: '7mm',
        label: '7mm(5.5+1.5)'
    },
]
export const thicknessLaminate = [

    {
        id: 1,
        value: '8mm',
        label: '8mm'
    },
    {
        id: 2,
        value: '12mm',
        label: '12mm'
    }

]

export const widthList = [
    {
        id: 1,
        value: '127-152',
        label: '5" - 5.99"'
    },
    {
        id: 2,
        value: '152-177',
        label: '6" - 6.99"'
    },
    {
        id: 3,
        value: '177-203',
        label: '7" - 7.99"'
    },
    {
        id: 4,
        value: '203-228',
        label: '8" - 8.99"'
    },
    {
        id: 5,
        value: '253-',
        label: '9.99" and up'
    }
]
export const widthHardwood = [
    {
        id: 1,
        value: '127-152',
        label: '5" - 5.99"'
    },
    {
        id: 2,
        value: '152-177',
        label: '6" - 6.99"'
    },
    {
        id: 3,
        value: '177-203',
        label: '7" - 7.99"'
    },
    {
        id: 4,
        value: '203-228',
        label: '8" - 8.99"'
    },
    {
        id: 5,
        value: '228-253',
        label: '9" - 9.99"'
    },
    {
        id: 6,
        value: '253-',
        label: '9.99" and up'
    }
]
export const widthSpc = [

    {
        id: 1,
        value: '177-203',
        label: '7" - 7.99"'
    },
    {
        id: 2,
        value: '203-228',
        label: '8" - 8.99"'
    },
    {
        id: 3,
        value: '228-',
        label: '9.99" and up'
    }
]
export const widthLaminate = [
    {
        id: 1,
        value: '127-152',
        label: '5" - 5.99"'
    },
    {
        id: 2,
        value: '152-177',
        label: '6" - 6.99"'
    },
    {
        id: 3,
        value: '177-203',
        label: '7" - 7.99"'
    },
    {
        id: 4,
        value: '228-',
        label: '9.99" and up'
    }
]

export const colourList = [
    {
        id: 1,
        value: 'beige',
        label: 'Beige'
    },
    {
        id: 2,
        value: 'brown',
        label: 'Brown'
    },
    {
        id: 3,
        value: 'grey',
        label: 'Grey'
    },
    {
        id: 4,
        value: 'white',
        label: 'White'
    },
    {
        id: 5,
        value: 'naturalwood',
        label: 'Natural Wood'
    }
]
export const colourHardwood = [
    {
        id: 1,
        value: 'beige',
        label: 'Beige'
    },
    {
        id: 2,
        value: 'darkbrown',
        label: 'Dark Brown'
    },
    {
        id: 3,
        value: 'grey',
        label: 'Grey'
    },
    {
        id: 4,
        value: 'white',
        label: 'White'
    },
    {
        id: 5,
        value: 'lightbrown',
        label: 'Light Brown'
    },
    {
        id: 6,
        value: 'red',
        label: 'Red'
    }
]
export const colourSpc = [
    {
        id: 1,
        value: 'beige',
        label: 'Beige'
    },
    {
        id: 2,
        value: 'lightbrown',
        label: 'Light Brown'
    },
    {
        id: 3,
        value: 'grey',
        label: 'Grey'
    },
    {
        id: 4,
        value: 'white',
        label: 'White'
    },
    {
        id: 5,
        value: 'darkbrown',
        label: 'Dark Brown'
    }
]
export const colourLaminate = [
    {
        id: 1,
        value: 'beige',
        label: 'Beige'
    },
    {
        id: 2,
        value: 'lightbrown',
        label: 'Light Brown'
    },
    {
        id: 3,
        value: 'grey',
        label: 'Grey'
    },
    {
        id: 4,
        value: 'white',
        label: 'White'
    },
    {
        id: 5,
        value: 'darkbrown',
        label: 'Dark Brown'
    }
]

export const brandList = [
    {
        id: 1,
        value: 'clivia',
        label: 'Clivia'
    },
    {
        id: 2,
        value: 'idealwood',
        label: 'Ideal Wood'
    },
    {
        id: 3,
        value: 'eurooriginal',
        label: 'Euro Original'
    },
    {
        id: 4,
        value: 'aquafix',
        label: 'Aquafix'
    },
    {
        id: 5,
        value: 'ecpremium',
        label: 'EC Premium'
    },
    {
        id: 6,
        value: 'toucan',
        label: 'Toucan'
    },
]
export const brandHardwood = [
    {
        id: 1,
        value: 'idealwood',
        label: 'Ideal Wood'
    },
    {
        id: 2,
        value: 'idealtexture',
        label: 'Ideal Texture'
    },

    {
        id: 3,
        value: 'idealclassic',
        label: 'Ideal Classic'
    },
    {
        id: 4,
        value: 'newclassic',
        label: 'New Classic (Herringbone)'
    },
    {
        id: 5,
        value: 'urbangenius',
        label: 'Urban Genius'
    },
    {
        id: 6,
        value: 'pravada',
        label: 'Pravada'
    },
]
export const brandSpc = [
    {
        id: 1,
        value: 'clivia',
        label: 'Clivia'
    },
    {
        id: 4,
        value: 'aquafix',
        label: 'Aquafix'
    },
    {
        id: 5,
        value: 'ecpremium',
        label: 'EC Premium'
    },
    {
        id: 6,
        value: 'toucan',
        label: 'Toucan'
    },
]
export const brandLaminate = [


    {
        id: 3,
        value: 'eurooriginal',
        label: 'Euro Original'
    },

    {
        id: 5,
        value: 'ecpremium',
        label: 'EC Premium'
    },
    {
        id: 6,
        value: 'toucan',
        label: 'Toucan'
    },
]
export const speciesHardwood = [
    {
        id: 1,
        value: 'sapele',
        label: 'Sapele'
    },
    {
        id: 2,
        value: 'maple',
        label: 'Maple'
    },
    {
        id: 3,
        value: 'hickory',
        label: 'Hickory'
    },
    {
        id: 4,
        value: 'oak',
        label: 'Oak'
    },
    {
        id: 5,
        value: 'walnut',
        label: 'Walnut'
    },
]
export const specHeadList = [
    {
        id: 1,
        value: 'flooringType',
        label: 'Flooring Type'
    }
]

export const details = [
    {

    },
    {
        id: 1,
        series: 'cliviaSpc',
        content: <p>
            <b>100% Waterproof</b> - It's the nature of SPC (Stone-Polymore-Composite) product.<br />
            <b>Authentic Wood Look and Touch</b> - Painted V-shaped bevel and EIR surface creates a more realistic wood look and touch. <br />
            <b>Floating and 5G Fold Down Lock</b> - The most advanced locking system for fastest & easiest installation. <br />
            <b>Underlayment Attached</b> 1.5mm ixpe underlayment attached. <br />
            <b>Wear Layer</b> 0.3mm (12mil) wear layer suitable for residential and light traffic commercial applications. <br />
            <b>Suiteable for installations on all level</b> <br />
        </p>

    },
    {
        id: 2,
        series: 'idealwoodIdealClassic',
        content: <p>
            Multi-Ply Stable core design gives structure stability <br />
            Smooth & satin gloss surface <br />
            4 Sides micro bevelled <br />
            Suitable for installation on all levels. <br />
            6.5 inches wider board <br />
            Lifetime structural warranty <br />
            25 year limited residential wear warranty
        </p>
    },
    {
        id: 3,
        series: 'idealwoodIdealTexture',
        content: <p>
            Multi-Ply Stable core design gives structure stability <br />
            Light brushed surface texture <br />
            4 Sides micro bevelled <br />
            Certified for underfloor heating system <br />
            6.5 inches wider board <br />
            Lifetime structural warranty <br />
            25 year limited residential wear warranty <br />
            3 year limited light commercial wear warranty <br />
            CA 93120 Phase 2 compliant for formaldehyde emission, EPA TSCA Title V
        </p>
    },
    {
        id: 4,
        series: 'idealwoodNewClassic',
        content: <p>
            <b>Herringbone</b> - 	Design for herringbone pattern installation. <br />
            <b>Authentic Touch</b> - A true touch of natural wood. <br />
            4 Sides micro bevelled <br />
            Certified for underfloor heating system <br />
            Suitable for installation on all levels. <br />
            Lifetime structural warranty <br />
            25 year limited residential wear warranty <br />
            CA 93120 Phase 2 compliant for formaldehyde emission, EPA TSCA Title V
        </p>
    },
    {
        id: 5,
        series: 'idealwoodUrbanGenius',
        content: <p>
            3mm dry solid sawn veneer <br />
            <b>Authentic Touch</b> - A true touch of natural wood. <br />
            3-ply stable core design <br />
            4 Sides micro bevelled <br />
            Certified for underfloor heating system <br />
        </p>
    },
    {
        id: 6,
        series: 'toucanLaminate',
        content: <p>
            <b>EIR Surface</b> - Embossed-In-Registered surface <br />
            12mm thickness <br />
            AC4 Durability Rating for commercial medium traffic and high traffic residential use<br />
            Droplock, easy installation <br />
        </p>
    },
    {
        id: 7,
        series: 'toucanSpc3',
        content: <p>
            <b>100% Waterproof</b><br />
            0.3mm (12mil) wear layer<br />
            <b>Underlayment Attached</b> - 1mm ixpe underlayment attached. <br />
            Suitable for installations on all level<br />
            Droplock, easy installation <br />
        </p>
    },
    {
        id: 8,
        series: 'toucanLooselay',
        content: <p>
            <b>100% Waterproof</b><br />
            <b>100% IMPERMEABLE</b><br />
            Enhanced Stability<br />
            Suitable for installations on all level<br />
            Stability Accrue<br />
            Superior Acoustics<br />
        </p>
    },
    {
        id: 9,
        series: 'swissaura',
        content: <p>
            <b>Abrasive Class 5</b> - for intense use in residential and commercial areas.<br />
            4-sided micro bevelled <br />
            <b>Certified Swiss Wood</b> - Declares the origin of the wood from Swiss forests and wood products from Swiss or Liechtenstein based companies
        </p>
    },

    {
        id: 10,
        series: 'aquafixGrandCru',
        content: <p>
            <b>100% Waterproof</b> - It's the nature of SPC (Stone-Polymore-Composite) product.<br />
            <b>Authentic Wood Look and Touch</b> - Painted V-shaped bevel and EIR surface creates a more realistic wood look and touch. <br />
            <b>Floating and 5G Fold Down Lock</b> - The most advanced locking system for fastest & easiest installation. <br />
            <b>Underlayment Attached</b> 1.5mm ixpe underlayment attached. <br />
            <b>Wear Layer</b> 0.5mm (20mil) wear layer suitable for residential and commercial applications. <br />
            <b>Suiteable for installations on all level</b> <br />
        </p>

    },
    {
        id: 11,
        series: 'toucanSpc2',
        content: <p>
            <b>100% Waterproof</b><br />
            <b>Total Thickness: 6.5mm</b> (5mm core + 1.5mm underlayment)<br />
            <b>0.5mm (20mil)</b> extremely durable wear layer<br />
            <b>Underlayment Attached</b> - 1.5mm ixpe underlayment attached. <br />
            Suitable for installations on all level<br />
            Droplock, easy installation <br />
        </p>
    },
    {
        id: 12,
        series: 'lesoleil',
        content: <p>
            <b>Extra Wide Plank</b> - 9.5in<br />
            <b>Total Thickness: 14mm</b> (with 2mm Venner)<br />
            <b>Matte Gloss Level</b><br />
            <b>European White Oak</b> <br />
            Suitable for installations on all level<br />
            <b>35 Year Warranty</b><br />
        </p>
    },
    {
        id: 13,
        series: 'lereve',
        content: <p>
            <b>Herring Bone Collection</b> - 7.5in wide<br />
            <b>Total Thickness: 14mm</b> (with 3mm Venner)<br />
            <b>Matte Gloss Level</b><br />
            <b>European White Oak</b> <br />
            Suitable for installations on all level<br />
            <b>35 Year Warranty</b><br />
        </p>
    },
    {
        id: 14,
        series: 'hautecouleur',
        content: <p>
            <b>Wide Plank</b> - 7.5in wide<br />
            <b>Total Thickness: 12mm</b> (with 2mm Venner)<br />
            <b>Matte Gloss Level</b><br />
            <b>European White Oak</b> <br />
            Suitable for installations on all level<br />
            <b>35 Year Warranty</b><br />
        </p>
    },
    {
        id: 15,
        series: 'artistique',
        content: <p>
            <b>Extra Wide Plank</b> - 9.5in wide<br />
            <b>Total Thickness: 14mm</b> (with 3mm Venner)<br />
            <b>Matte Gloss Level</b><br />
            <b>European White Oak</b> <br />
            Suitable for installations on all level<br />
            <b>35 Year Warranty</b><br />
        </p>
    },
    {
        id: 16,
        series: 'canvas',
        content: <p>
            <b>Extra Wide Plank</b> - 8.5in wide<br />
            <b>Total Thickness: 14mm</b> (with 3mm Venner)<br />
            <b>Matte Gloss Level</b><br />
            <b>European White Oak</b> <br />
            Suitable for installations on all level<br />
            <b>35 Year Warranty</b><br />
        </p>
    },

]

export const features = [
    {
        sku: "CL321",
        features: [
            {
                icon: '../images/features-icon/Waterproof.png',
                desc: '100% Waterproof',
            },
            {
                icon: '../images/features-icon/Underlay.png',
                desc: 'Underlayment Attached',
            },

        ]


    }
]

export const products = [
    {
        id: 1,
        title: "Clivia SPC Floors",
        desc: "Colour: CL321 Bridal Veil Falls",
        metaDesc: "",
        sku: "cl321",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "lightbrown",
            "darkbrown"
        ],
        brand: "clivia",
        country: "China",
        overview: details[1].content,
        warranty: "lifetime residential, 10 years commercial",
        image: [
            "../images/spc/clivia/installed/cl321i.jpg",
            "../images/spc/clivia/full/cl321f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 2,
        title: "Clivia SPC Floors",
        desc: "Colour: CL322 Cascade Creek",
        sku: "CL322",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl322i.jpg",
            "../images/spc/clivia/full/cl322f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 3,
        title: "Clivia SPC Floors",
        desc: "Colour: CL323 Cheam Lake",
        sku: "CL323",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl323i.jpg",
            "../images/spc/clivia/full/cl323f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 4,
        title: "Clivia SPC Floors",
        desc: "Colour: CL324 Boston Bar",
        sku: "CL324",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "white",
            "grey"
        ],
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl324i.jpg",
            "../images/spc/clivia/full/cl324f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 5,
        title: "Clivia SPC Floors",
        desc: "Colour: CL325 Osoyoos",
        sku: "CL325",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl325i.jpg",
            "../images/spc/clivia/full/cl325f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 6,
        title: "Clivia SPC Floors",
        desc: "Colour: CL326 Mistaya",
        sku: "CL326",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "white",
            "lightbrown"
        ],
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl326i.jpg",
            "../images/spc/clivia/full/cl326f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 7,
        title: "Clivia SPC Floors",
        desc: "Colour: CL327 Eagle Plains",
        sku: "CL327",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "darkbrown",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl327i.jpg",
            "../images/spc/clivia/full/cl327f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 8,
        title: "Clivia SPC Floors",
        desc: "Colour: CL328 Sunshine Coast",
        sku: "CL328",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "white",
            "grey"
        ],
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl328i.jpg",
            "../images/spc/clivia/full/cl328f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 9,
        title: "Clivia SPC Floors",
        desc: "Colour: CL329 Rose Valley",
        sku: "CL329",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl329i.jpg",
            "../images/spc/clivia/full/cl329f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 10,
        title: "Clivia SPC Floors",
        desc: "Colour: CL330 Skaha",
        sku: "CL330",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "lightbrown",
            "darkbrown"
        ],
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl330i.jpg",
            "../images/spc/clivia/full/cl330f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.49,

    },
    {
        id: 11,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2222 Hickory Titanium Blonde",
        sku: "MT2222",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "grey",
            "beige"
        ],
        species: "hickory",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2222-hickory-titanium-blonde.jpg"
        ],

    },
    {
        id: 12,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2211 Sapele, Tropical Classic",
        sku: "MT2211",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "red",
            "darkbrown"
        ],
        species: "sapele",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2211-Sapele-Tropical-Classic.jpg"
        ],

    },
    {
        id: 13,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2213 Sapele, Leather",
        sku: "MT2213",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "red",
            "darkbrown"
        ],
        species: "sapele",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2213-Sapele-Leather.jpg"
        ],

    },
    {
        id: 14,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2217 Maple, Macchiato",
        sku: "MT2217",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "maple",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2217-Maple-Macchiato.jpg"
        ],

    },
    {
        id: 15,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2220 Maple, Classic",
        sku: "MT2220",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "maple",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2220-Maple-Classic.jpg"
        ],

    },
    {
        id: 16,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2221 Hickory, Chateau",
        sku: "MT2221",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "Hickory",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2221-Hickory-Chateau.jpg"
        ],

    },
    {
        id: 17,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2223 Hickory, Bordeaux",
        sku: "MT2223",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "Hickory",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2223-hickory-Bordeaux.jpg"
        ],

    },
    {
        id: 18,
        title: "Ideal Wood - Ideal Classic",
        desc: "Colour: MT-2224 Authentic Hickory",
        sku: "MT2224",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "6-1/2\"",
        widthinterval: "152-177",
        dimension: "1/2\" thick X 6-1/2\" wide X 48\" in length",
        package: "26.1sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "hickory",
        brand: [
            "idealwood",
            "idealclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[2].content,
        image: [
            "../images/hardwood/idealwood/idealclassic/mt2224-authentic-hickory.jpg"
        ],

    },
    {
        id: 19,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-22G1 Oak, Authentic Grey",
        sku: "MT22G1",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt22g1/authenticgrey (6).jpg",
        ],

    },
    {
        id: 20,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-1224 Oak, Barnard",
        sku: "MT1224",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1224/barnard (6).jpg",
        ],

    },
    {
        id: 21,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-1804 Oak, Satin White",
        sku: "MT1804",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt1804/satinwhite (6).jpg",
        ],

    },
    {
        id: 22,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2202 Oak, Original",
        sku: "MT2202",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2202/original (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2202/original (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2202/original (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2202/original (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2202/original (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2202/original (6).jpg",
        ],

    },
    {
        id: 23,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2206 Oak, Mascara",
        sku: "MT2206",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2206/mascara (6).jpg",
        ],

    },
    {
        id: 24,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2209 Oak, Honolulu",
        sku: "MT2209",
        price: 'call for pricing',
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2209/honolulu (6).jpg",
        ],

    },
    {
        id: 25,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2225 Oak, Princeton",
        sku: "MT2225",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2225/princeton (6).jpg",
        ],

    },
    {
        id: 26,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2226 Oak, Baylor",
        sku: "MT2226",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "beige",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2226/baylor (6).jpg",
        ],

    },
    {
        id: 27,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2229 Oak, Cappadocia",
        sku: "MT2229",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2229/cappadocia (6).jpg",
        ],

    },
    {
        id: 28,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2230 Oak, Fordham",
        sku: "MT2230",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2230/fordham (6).jpg",
        ],

    },
    {
        id: 29,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2234 Oak, Villanova",
        sku: "MT2234",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2234/villanova (6).jpg",
        ],

    },
    {
        id: 30,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2236 Oak, Kenyon",
        sku: "MT2236",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2236/kenyon (6).jpg",
        ],

    },
    {
        id: 31,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2237 Oak, Grinnel",
        sku: "MT2237",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "grey"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2237/grinnell (6).jpg",
        ],

    },
    {
        id: 32,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2238 Oak, Lehigh",
        sku: "MT2238",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2238/lehigh (6).jpg",
        ],

    },
    {
        id: 33,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2240 Oak, Berkeley",
        sku: "MT2240",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "lightbrown",
            "beige"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2240/berkeley (6).jpg",
        ],

    },
    {
        id: 34,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2243 Oak, Amherst",
        sku: "MT2243",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2243/amherst (6).jpg",
        ],

    },
    {
        id: 35,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2246 Oak, Pomona",
        sku: "MT2246",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2246/pomona (6).jpg",
        ],

    },
    {
        id: 36,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2248 Oak, Claremont",
        sku: "MT2248",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "lightbrown",
            "beige"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2248/claremont (6).jpg",
        ],

    },
    {
        id: 37,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2250 Oak, Purdue",
        sku: "MT2250",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2250/purdue (6).jpg",
        ],

    },
    {
        id: 38,
        title: "Ideal Wood - Ideal Texture",
        desc: "Colour: MT-2252 Oak, Clemson",
        sku: "MT2252",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "7\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7\" wide X 71\" in length",
        package: "20.69sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "lightbrown"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "idealtexture"
        ],
        country: "China",
        warranty: "25 years residential, 3 years commertial lifetime structural",
        overview: details[3].content,
        image: [
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (1).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (2).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (3).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (4).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (5).jpg",
            "../images/hardwood/idealwood/idealtexture/mt2252/clemson (6).jpg",
        ],

    },
    {
        id: 39,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6290 Alsace",
        sku: "MTH6290",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "beige",
            "lightbrown"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Feature-Image-alsace2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Roomscene-Alsace1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Roomscene-Alsace2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Roomscene-Alsace3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Single-Panel-ALSACE.jpg",
            "../images/hardwood/idealwood/newclassic/mth6290/New-Classic-Herringbone-Top-view-ALSACE.jpg",

        ],

    },
    {
        id: 40,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6291 Napa",
        sku: "MTH6291",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Feature-Image-napa2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Roomscene-napa1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Roomscene-napa2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Roomscene-napa3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Single-Panel-NAPA.jpg",
            "../images/hardwood/idealwood/newclassic/mth6291/New-Classic-Herringbone-Top-view-NAPA.jpg",

        ],

    },
    {
        id: 41,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6292 Catalonia",
        sku: "MTH6292",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Feature-Image-catalonia2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Roomscene-catalonia1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Roomscene-catalonia2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Roomscene-catalonia3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Single-Panel-CATALONIA.jpg",
            "../images/hardwood/idealwood/newclassic/mth6292/New-Classic-Herringbone-Top-view-CATALONIA.jpg",

        ],

    },
    {
        id: 68,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6293 Saint Emilion",
        sku: "MTH6293",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Feature-Image-saint2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Roomscene-Saint-Emilion1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Roomscene-Saint-Emilion2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Roomscene-Saint-Emilion3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Single-Panel-Saint-Emilion.jpg",
            "../images/hardwood/idealwood/newclassic/mth6293/New-Classic-Herringbone-Top-view-Saint-Emilion.jpg",

        ],

    },
    {
        id: 42,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6294 Burgundy",
        sku: "MTH6294",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Feature-Image-burgundy2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Roomscene-Burgundy1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Roomscene-Burgundy2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Roomscene-Burgundy3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Single-Panel-BURGUNDY.jpg",
            "../images/hardwood/idealwood/newclassic/mth6294/New-Classic-Herringbone-Top-view-BURGUNDY.jpg",

        ],

    },
    {
        id: 43,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6295 Barossa",
        sku: "MTH6295",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "grey",
            "darkbrown"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Feature-Image-barossa2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Roomscene-Barossa1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Roomscene-Barossa2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Roomscene-Barossa3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Single-Panel-BAROSSA.jpg",
            "../images/hardwood/idealwood/newclassic/mth6295/New-Classic-Herringbone-Top-view-BAROSSA.jpg",

        ],

    },
    {
        id: 44,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6296 Veneto",
        sku: "MTH6296",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "lightbrown"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Feature-Image-veneto2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Roomscene-Veneto1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Roomscene-Veneto2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Roomscene-Veneto3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Single-Panel-VENETO.jpg",
            "../images/hardwood/idealwood/newclassic/mth6296/New-Classic-Herringbone-Top-view-VENETO.jpg",

        ],

    },
    {
        id: 45,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6297 Lavaux",
        sku: "MTH6297",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Feature-Image-lavaux2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Roomscene-LAVAUX1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Roomscene-LAVAUX2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Roomscene-LAVAUX3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Single-Panel-LAVAUX.jpg",
            "../images/hardwood/idealwood/newclassic/mth6297/New-Classic-Herringbone-Top-view-LAVAUX.jpg",

        ],

    },
    {
        id: 46,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6298 Pauillac",
        sku: "MTH6298",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Feature-Image-pauillac2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Roomscene-Pauillac1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Roomscene-Pauillac2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Roomscene-Pauillac3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Single-Panel-PAUILLAC.jpg",
            "../images/hardwood/idealwood/newclassic/mth6298/New-Classic-Herringbone-Top-view-PAUILLAC.jpg",

        ],

    },
    {
        id: 47,
        title: "Ideal Wood - New Classic",
        desc: "Colour: MTH-6299 Okanagan",
        sku: "MTH6299",
        price: "call for pricing",
        category: "hardwood",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "127-152",
        dimension: "1/2\" thick X 5\" wide X 24\" in length",
        package: "16.4sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "newclassic"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[4].content,
        image: [
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Feature-Image-okanagan2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Roomscene-Okanagan1.jpg",
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Roomscene-Okanagan2.jpg",
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Roomscene-Okanagan3.jpg",
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Single-Panel-OKANAGAN.jpg",
            "../images/hardwood/idealwood/newclassic/mth6299/New-Classic-Herringbone-Top-view-OKANAGAN.jpg",

        ],

    },
    {
        id: 48,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89019 Oak, Antique Cigar",
        sku: "LA89019",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique.png",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar-3.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar-5.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/89019-Antique-Cigar-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89019/Oak-Antique-Cigar.jpg",

        ],

    },
    {
        id: 49,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89032 Oak, Metro Grey",
        sku: "LA89032",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-3.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/89032-Metro-Grey-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89032/Oak-Metro-Grey.jpg",

        ],

    },
    {
        id: 50,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22L11 Oak, Brooklyn Grey",
        sku: "22L11",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "grey,"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/22L11-Brookyln-Grey-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22l11/Oak-Brooklyn-Grey.jpg",

        ],

    },
    {
        id: 51,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22L13 Oak, Original",
        sku: "22L13",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Oak-Original-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/22L13-Original-7.jpg",
            "../images/hardwood/idealwood/urbangenius/22l13/Oak-Original.jpg",

        ],

    },
    {
        id: 52,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J34 Oak, Fuji",
        sku: "22J34",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j34/22J34-Oak-Fuji.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-4-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-6-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Fuji-1-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Oak-Fuji.jpg",
            "../images/hardwood/idealwood/urbangenius/22j34/Oak-Fuji-Top-View.jpg",

        ],

    },
    {
        id: 53,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89003 Oak, Monet",
        sku: "LA89003",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet.png",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/89003-Monet-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89003/Oak-Monet.jpg",

        ],

    },
    {
        id: 54,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89024 Oak, Cashmere",
        sku: "LA89024",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "lightbrown",
            "grey"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/89024-Cashmere-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89024/Oak-Cashmere.jpg",

        ],

    },
    {
        id: 55,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J06 Oak, Sakura",
        sku: "22J06",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "grey",
        ],
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-3-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/22J06-Sakura-7.jpg",
            "../images/hardwood/idealwood/urbangenius/22j06/Oak-Sakura.jpg",

        ],

    },
    {
        id: 56,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J10 Oak, Oasis",
        sku: "22J10",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/22J10-Oasis-7.jpg",
            "../images/hardwood/idealwood/urbangenius/22j10/Oak-Oasis.jpg",

        ],

    },
    {
        id: 57,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J23 Oak, Whisky Bar",
        sku: "22J23",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j23/22J23-Oak-whiskey-bar.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whiskey-Bar-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whisky-Bar-2-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whisky-Bar-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whisky-Bar-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whisky-Bar-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Whisky-Bar-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Oak-Whisky-Bar.jpg",
            "../images/hardwood/idealwood/urbangenius/22j23/Oak-Whisky-Bar-Top-View.jpg",

        ],

    },
    {
        id: 58,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J28 Oak, Adela",
        sku: "22J28",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j28/22J28-Oak-Adela.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Oak-Adela.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-4-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-4-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-5-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Adela-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j28/Oak-Adela-Top-View.jpg",

        ],

    },
    {
        id: 59,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J30 Oak, Dawn",
        sku: "22J30",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j30/22J30-Oak-Dawn.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-2-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-3-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-5-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Dawn-4-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j30/Oak-Dawn-Top-View.jpg",

        ],

    },
    {
        id: 60,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J31 Oak, Alabaster",
        sku: "22J31",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j31/22J31-Oak-Alabaster-with-New.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-2-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Alabaster-4-.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Oak-Alabaster.jpg",
            "../images/hardwood/idealwood/urbangenius/22j31/Oak-Alabaster-Top-View.jpg",

        ],

    },
    {
        id: 61,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22L07 Oak, Creama",
        sku: "22L07",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/22L07-Creama-7.jpg",
            "../images/hardwood/idealwood/urbangenius/22l07/Oak-Creama.jpg",

        ],

    },
    {
        id: 62,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89022 Oak, Galaxy (Select Grade)",
        sku: "LA89022",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: [
            "darkbrown",
            "grey"
        ],
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89022/Oak-Galaxy-Resized.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/89022-Galaxy-1-.jpg",
            "../images/hardwood/idealwood/urbangenius/la89022/Oak-Galaxy.jpg",

        ],

    },
    {
        id: 63,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89031 Oak, Chamonix (Smoked)",
        sku: "LA89031",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/89031-Charmonix-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89031/Oak-Chamonix.jpg",

        ],

    },
    {
        id: 64,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89033 Oak, Platinum (Metallic)",
        sku: "LA89033",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "grey",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius",
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-with-Metallic-matte.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/LA89033-Platinum.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-2.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-4.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/89033-Platinum-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89033/Oak-Platinum.jpg",

        ],

    },
    {
        id: 65,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J13 Oak, Vancouver (Smoked)",
        sku: "22J13",
        price: "call for pricing",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-3.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/Oak-Vancouver.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-4-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j13/22J13-Vancouver-7.jpg",

        ],

    },
    {
        id: 66,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: LA89029 Walnut, Royal",
        sku: "LA89029",
        price: "CALL FOR PRICING",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "walnut",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/la89029/Royal-Walnut.png",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-5.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-6.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/89029-Walnut-Royal-7.jpg",
            "../images/hardwood/idealwood/urbangenius/la89029/Walnut-Royal.jpg",

        ],

    },
    {
        id: 67,
        title: "Ideal Wood - Urban Genius",
        desc: "Colour: 22J19 European Walnut",
        sku: "22J19",
        price: "CALL FOR PRICING",
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X Random Length up to 73\"",
        package: "30.27sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "walnut",
        brand: [
            "idealwood",
            "urbangenius"
        ],
        country: "China",
        warranty: "25 years residential, lifetime structural",
        overview: details[5].content,
        image: [
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-2.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-1-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-2-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-4.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-5.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-6.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/22J19-European-Walnut-1.jpg",
            "../images/hardwood/idealwood/urbangenius/22j19/European-Walnut.jpg",

        ],

    },
    {
        id: 68,
        title: "Toucan Laminate TF6201",
        desc: "Colour: TF 6201",
        sku: "6201",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6201_A.jpg"
        ],
    },
    {
        id: 69,
        title: "Toucan Laminate TF6202",
        desc: "Colour: TF 6202",
        sku: "6202",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6202_A.jpg"
        ],
    },
    {
        id: 70,
        title: "Toucan Laminate TF6203",
        desc: "Colour: TF 6203",
        sku: "6203",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: [
            "lightbrown",
            "beige",
        ],
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6203_A.jpg"
        ],
    },
    {
        id: 71,
        title: "Toucan Laminate TF6205",
        desc: "Colour: TF 6205",
        sku: "6205",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6205_A.jpg"
        ],
    },
    {
        id: 72,
        title: "Toucan Laminate TF6206",
        desc: "Colour: TF 6206",
        sku: "6206",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6206_A.jpg"
        ],
    },
    {
        id: 73,
        title: "Toucan Laminate TF6207",
        desc: "Colour: TF 6207",
        sku: "6207",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "beige",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6207_A.jpg"
        ],
    },
    {
        id: 74,
        title: "Toucan Laminate TF6208",
        desc: "Colour: TF 6208",
        sku: "6208",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6208_A.jpg"
        ],
    },
    {
        id: 75,
        title: "Toucan Laminate TF6209",
        desc: "Colour: TF 6209",
        sku: "6209",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6209_A.jpg"
        ],
    },
    {
        id: 76,
        title: "Toucan Laminate TF6210",
        desc: "Colour: TF 6210",
        sku: "6210",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6210_A.jpg"
        ],
    },
    {
        id: 77,
        title: "Toucan Laminate TF6211",
        desc: "Colour: TF 6211",
        sku: "6211",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6211_A.jpg"
        ],
    },
    {
        id: 78,
        title: "Toucan Laminate TF6212",
        desc: "Colour: TF 6212",
        sku: "6212",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6212_A.jpg"
        ],
    },
    {
        id: 79,
        title: "Toucan Laminate TF6213",
        desc: "Colour: TF 6213",
        sku: "6213",
        price: 2.59,
        isOnSale: true,
        newPrice: 2.19,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/group4/6213_A.jpg"
        ],
    },
    {
        id: 80,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 301",
        sku: "SPC301PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/301_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 81,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 302",
        sku: "SPC302PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/302_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 82,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 303",
        sku: "SPC303PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "darkbrown",
            "grey",
        ],
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/303_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 83,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 304",
        sku: "SPC304PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/304_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 84,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 305",
        sku: "SPC305PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/305_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 85,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 306",
        sku: "SPC306PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/306_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 86,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 307",
        sku: "SPC307PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/307_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 87,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 308",
        sku: "SPC308PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "beige",
            "lightbrown"
        ],
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/308_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 88,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 309",
        sku: "SPC309PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/309_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 89,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 310",
        sku: "SPC310PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/310_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 90,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 311",
        sku: "SPC311PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/311_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 91,
        title: "Toucan - SPC 3 Series",
        desc: "Colour: 312",
        sku: "SPC312PF",
        price: 2.59,
        category: "vinyl",
        thickness: "4.2mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "28.84sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[7].content,
        image: [
            "../images/spc/toucan/spc3/312_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.19,

    },
    {
        id: 92,
        title: "Toucan - Looselay",
        desc: "Colour: TF601",
        sku: "TF601",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/601_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 93,
        title: "Toucan - Looselay",
        desc: "Colour: TF602",
        sku: "TF602",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: [
            "grey",
            "darkbrown"
        ],
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/602_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 94,
        title: "Toucan - Looselay",
        desc: "Colour: TF603",
        sku: "TF603",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/603_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 95,
        title: "Toucan - Looselay",
        desc: "Colour: TF604",
        sku: "TF604",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/604_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 96,
        title: "Toucan - Looselay",
        desc: "Colour: TF605",
        sku: "TF605",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/605_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 97,
        title: "Toucan - Looselay",
        desc: "Colour: TF606",
        sku: "TF606",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: [
            "grey",
            "darkbrown",
        ],
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/606_A.jpg",
        ],

        isOnSale: true,
        newPrice: 2.69,
    },
    {
        id: 98,
        title: "Toucan - Looselay",
        desc: "Colour: TF607",
        sku: "TF607",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/607_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 99,
        title: "Toucan - Looselay",
        desc: "Colour: TF608",
        sku: "TF608",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/608_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 100,
        title: "Toucan - Looselay",
        desc: "Colour: TF609",
        sku: "TF609",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/609_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 101,
        title: "Toucan - Looselay",
        desc: "Colour: TF610",
        sku: "TF610",
        price: 3.49,
        category: "vinyl",
        thickness: "5mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "5mm thick X 7-1/4\" wide X 48\" in length",
        package: "25.04sqft/carton",
        material: "Looselay",
        wearlayer: "0.5mm (20mil)",
        installation: "Glue Down",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[8].content,
        image: [
            "../images/spc/toucan/looselay/610_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.69,

    },
    {
        id: 102,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Shadow",
        sku: "shadow",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "grey",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/shadow/shadow-3.jpg",
            "../images/laminate/eurooriginal/swissaura/shadow/shadow.jpg",
            "../images/laminate/eurooriginal/swissaura/shadow/shadow2.jpg",
            "../images/laminate/eurooriginal/swissaura/shadow/shadow3.jpg"
        ],

    },
    {
        id: 103,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Opera",
        sku: "opera",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "darkbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/opera/Opera-3.jpg",
            "../images/laminate/eurooriginal/swissaura/opera/Opera.jpg",
            "../images/laminate/eurooriginal/swissaura/opera/Opera2.jpg",
            "../images/laminate/eurooriginal/swissaura/opera/Opera3.jpg"
        ],

    },
    {
        id: 104,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Menzberg",
        sku: "menzberg",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "beige",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/menzberg/Menzburg.jpg",
            "../images/laminate/eurooriginal/swissaura/menzberg/Menzberg.jpg",
            "../images/laminate/eurooriginal/swissaura/menzberg/Menzberg2.jpg",
            "../images/laminate/eurooriginal/swissaura/menzberg/Menzberg3.jpg"
        ],

    },
    {
        id: 105,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: November",
        sku: "november",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "grey",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/november/November-3.jpg",
            "../images/laminate/eurooriginal/swissaura/november/November-1.jpg",
            "../images/laminate/eurooriginal/swissaura/november/November2.jpg",
            "../images/laminate/eurooriginal/swissaura/november/November3.jpg"
        ],

    },
    {
        id: 106,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Mr. Grey",
        sku: "mrgrey",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "grey",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/mrgrey/Mr-Grey.jpg",
            "../images/laminate/eurooriginal/swissaura/mrgrey/Mr.-Grey.jpg",
            "../images/laminate/eurooriginal/swissaura/mrgrey/Mr.-Grey2.jpg",
            "../images/laminate/eurooriginal/swissaura/mrgrey/Mr.-Grey3.jpg"
        ],

    },
    {
        id: 107,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Lake Lucerne",
        sku: "lakelucerne",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "lightbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/lakelucerne/Lake-Lucerne-3.jpg",
            "../images/laminate/eurooriginal/swissaura/lakelucerne/Lake-Lucerne.jpg",
            "../images/laminate/eurooriginal/swissaura/lakelucerne/Lake-Lucerne2.jpg",
            "../images/laminate/eurooriginal/swissaura/lakelucerne/Lake-Lucerne3.jpg"
        ],

    },
    {
        id: 108,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Heidi",
        sku: "heidi",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "grey",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/heidi/Heidi-3.jpg",
            "../images/laminate/eurooriginal/swissaura/heidi/Heidi.jpg",
            "../images/laminate/eurooriginal/swissaura/heidi/Heidi2.jpg",
            "../images/laminate/eurooriginal/swissaura/heidi/Heidi3.jpg"
        ],

    },
    {
        id: 109,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Limewashed Oak",
        sku: "limewashedoak",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "lightbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/limewashedoak/Limewashed-Oak-2.jpg",
            "../images/laminate/eurooriginal/swissaura/limewashedoak/Limewashed-Oak4.jpg",
            "../images/laminate/eurooriginal/swissaura/limewashedoak/Limewashed-Oak2.jpg",
            "../images/laminate/eurooriginal/swissaura/limewashedoak/Limewashed-Oak3.jpg"
        ],

    },
    {
        id: 110,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Bianco",
        sku: "bianco",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "beige",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/bianco/Bianco-3.jpg",
            "../images/laminate/eurooriginal/swissaura/bianco/Bianco.jpg",
            "../images/laminate/eurooriginal/swissaura/bianco/Bianco2-1.jpg",
            "../images/laminate/eurooriginal/swissaura/bianco/Bianco-2.jpg"
        ],

    },
    {
        id: 111,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Victoria",
        sku: "victoria",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "darkbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/victoria/Victoria-4.jpg",
            "../images/laminate/eurooriginal/swissaura/victoria/Victoria.jpg",
            "../images/laminate/eurooriginal/swissaura/victoria/Victoria2.jpg",
            "../images/laminate/eurooriginal/swissaura/victoria/Victoria3.jpg"
        ],

    },
    {
        id: 112,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Solar",
        sku: "solar",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: [
            "grey",
            "lightbrown",
        ],
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/solar/Solar-3.jpg",
            "../images/laminate/eurooriginal/swissaura/solar/solar.jpg",
            "../images/laminate/eurooriginal/swissaura/solar/solar2.jpg",
            "../images/laminate/eurooriginal/swissaura/solar/solar3.jpg"
        ],

    },
    {
        id: 113,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Maltese",
        sku: "maltese",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "lightbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/maltese/Maltese-4.jpg",
            "../images/laminate/eurooriginal/swissaura/maltese/Maltese.jpg",
            "../images/laminate/eurooriginal/swissaura/maltese/Maltese2.jpg",
            "../images/laminate/eurooriginal/swissaura/maltese/Maltese3.jpg"
        ],

    },
    {
        id: 114,
        title: "Euro Original - Swiss Aura",
        desc: "Colour: Waikiki Beach",
        sku: "waikikibeach",
        price: "Call For Pricing",
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 54\" in length",
        package: "14.33sqft/carton",
        material: "Laminate",
        installation: "Floating",
        acrating: "AC5",
        colour: "lightbrown",
        brand: "eurooriginal",
        country: "Switzerland",
        warranty: "30 years residential, 5 years commercial",
        overview: details[9].content,
        image: [
            "../images/laminate/eurooriginal/swissaura/waikikibeach/Waikiki-Beach-3.jpg",
            "../images/laminate/eurooriginal/swissaura/waikikibeach/Waikiki-Beach.jpg",
            "../images/laminate/eurooriginal/swissaura/waikikibeach/Waikiki-Beach2.jpg",
            "../images/laminate/eurooriginal/swissaura/waikikibeach/Waikiki-Beach3.jpg"
        ],

    },

    {
        id: 115,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28941 Petrus",
        sku: "EC28941",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28941/Grand-Cru-feature-image-for-web-petrus2.jpg",
            "../images/spc/aquafix/grandcru/ec28941/Grand-Cru-single-panel-for-web-Petrus.jpg",
            "../images/spc/aquafix/grandcru/ec28941/Grand-Cru-top-view-for-web-Petrus.jpg",
            "../images/spc/aquafix/grandcru/ec28941/Room-Scene-for-web-Petrus-1.jpg",
            "../images/spc/aquafix/grandcru/ec28941/Room-Scene-for-web-Petrus-2.jpg",
            "../images/spc/aquafix/grandcru/ec28941/Room-Scene-for-web-Petrus-3.jpg",
        ],

    },
    {
        id: 116,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28942 Ausone",
        sku: "EC28942",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28942/Grand-Cru-feature-image-for-web-ausone2.jpg",
            "../images/spc/aquafix/grandcru/ec28942/Grand-Cru-single-panel-for-web-Ausone.jpg",
            "../images/spc/aquafix/grandcru/ec28942/Grand-Cru-top-view-for-web-Ausone.jpg",
            "../images/spc/aquafix/grandcru/ec28942/Room-Scene-for-web-Ausone-1-1.jpg",
            "../images/spc/aquafix/grandcru/ec28942/Room-Scene-for-web-Ausone-2.jpg",
            "../images/spc/aquafix/grandcru/ec28942/Room-Scene-for-web-Ausone-3.jpg",
        ],

    },
    {
        id: 117,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28943 Duhart",
        sku: "EC28943",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: [
            "lightbrown",
            "grey",
        ],
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28943/Grand-Cru-feature-image-for-web-duhart2.jpg",
            "../images/spc/aquafix/grandcru/ec28943/Grand-Cru-single-panel-for-web-Duhart.jpg",
            "../images/spc/aquafix/grandcru/ec28943/Grand-Cru-top-view-for-web-Duhart.jpg",
            "../images/spc/aquafix/grandcru/ec28943/Room-Scene-for-web-Duhart-1.jpg",
            "../images/spc/aquafix/grandcru/ec28943/Room-Scene-for-web-Duhart-2.jpg",
            "../images/spc/aquafix/grandcru/ec28943/Room-Scene-for-web-Duhart-3.jpg",
        ],

    },
    {
        id: 118,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28944 Mouton",
        sku: "EC28944",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28944/Grand-Cru-feature-image-for-web-mouton2.jpg",
            "../images/spc/aquafix/grandcru/ec28944/Grand-Cru-single-panel-for-web-Mouton.jpg",
            "../images/spc/aquafix/grandcru/ec28944/Grand-Cru-top-view-for-web-Mouton.jpg",
            "../images/spc/aquafix/grandcru/ec28944/Room-Scene-for-web-Mouton-1.jpg",
            "../images/spc/aquafix/grandcru/ec28944/Room-Scene-for-web-Mouton-2.jpg",
            "../images/spc/aquafix/grandcru/ec28944/Room-Scene-for-web-Mouton-3.jpg",
        ],

    },
    {
        id: 119,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28945 Cheval",
        sku: "EC28945",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28945/Grand-Cru-feature-image-for-web-cheval2.jpg",
            "../images/spc/aquafix/grandcru/ec28945/Grand-Cru-single-panel-for-web-Cheval.jpg",
            "../images/spc/aquafix/grandcru/ec28945/Grand-Cru-top-view-for-web-Cheval.jpg",
            "../images/spc/aquafix/grandcru/ec28945/Room-Scene-for-web-Cheval-1.jpg",
            "../images/spc/aquafix/grandcru/ec28945/Room-Scene-for-web-Cheval-2.jpg",
            "../images/spc/aquafix/grandcru/ec28945/Room-Scene-for-web-Cheval-3.jpg",
        ],

    },
    {
        id: 120,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28947 Montrose",
        sku: "EC28947",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28947/Grand-Cru-feature-image-for-web-montrose2.jpg",
            "../images/spc/aquafix/grandcru/ec28947/Grand-Cru-single-panel-for-web-Montrose.jpg",
            "../images/spc/aquafix/grandcru/ec28947/Grand-Cru-top-view-for-web-Montrose.jpg",
            "../images/spc/aquafix/grandcru/ec28947/Room-Scene-for-web-Montrose-1.jpg",
            "../images/spc/aquafix/grandcru/ec28947/Room-Scene-for-web-Montrose-2.jpg",
            "../images/spc/aquafix/grandcru/ec28947/Room-Scene-for-web-Montrose-3.jpg",
        ],

    },
    {
        id: 121,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28948 Pichon",
        sku: "EC28948",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28948/Grand-Cru-feature-image-for-web-pichon2.jpg",
            "../images/spc/aquafix/grandcru/ec28948/Grand-Cru-single-panel-for-web-Pichon.jpg",
            "../images/spc/aquafix/grandcru/ec28948/Grand-Cru-top-view-for-web-Pichon.jpg",
            "../images/spc/aquafix/grandcru/ec28948/Room-Scene-for-web-Pichon-1.jpg",
            "../images/spc/aquafix/grandcru/ec28948/Room-Scene-for-web-Pichon-2.jpg",
            "../images/spc/aquafix/grandcru/ec28948/Room-Scene-for-web-Pichon-3.jpg",
        ],

    },
    {
        id: 122,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28949 Latour",
        sku: "EC28949",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: [
            "lightbrown",
            "grey",
        ],
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28949/Grand-Cru-feature-image-for-web-latour2.jpg",
            "../images/spc/aquafix/grandcru/ec28949/Grand-Cru-single-panel-for-web-Latour.jpg",
            "../images/spc/aquafix/grandcru/ec28949/Grand-Cru-top-view-for-web-Latour.jpg",
            "../images/spc/aquafix/grandcru/ec28949/Room-Scene-for-web-Latour-1.jpg",
            "../images/spc/aquafix/grandcru/ec28949/Room-Scene-for-web-Latour-2.jpg",
            "../images/spc/aquafix/grandcru/ec28949/Room-Scene-for-web-Latour-3.jpg",
        ],

    },
    {
        id: 123,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28952 Romanee",
        sku: "EC28952",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-feature-image-for-web-romanee2.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-single-panel-for-web-Romanee.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-top-view-for-web-Romanee.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-1.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-2.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-3.jpg",
        ],

    },
    {
        id: 124,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28960 Margaux",
        sku: "EC28960",
        price: "call for pricing",
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28960/Grand-Cru-feature-image-for-web-margaux2.jpg",
            "../images/spc/aquafix/grandcru/ec28960/Grand-Cru-single-panel-for-web-Margaux.jpg",
            "../images/spc/aquafix/grandcru/ec28960/Grand-Cru-top-view-for-web-Margaux.jpg",
            "../images/spc/aquafix/grandcru/ec28960/Room-Scene-for-web-Margaux-1.jpg",
            "../images/spc/aquafix/grandcru/ec28960/Room-Scene-for-web-Margaux-2.jpg",
            "../images/spc/aquafix/grandcru/ec28960/Room-Scene-for-web-Margaux-3.jpg",
        ],

    },

    {
        id: 150,
        title: "Soundproof EVA Foam Underlayment",
        desc: "3.0mm - IIC 73 / STC 72",
        sku: "UL002",
        price: 0.35,
        category: "accessory",
        thickness: "3.0mm",
        package: "200sqft/roll",
        material: "EVA Foam",
        installation: "",
        country: "",
        image: [
            "../images/underlayment/ul002/evaUnderlay.png"
        ],
        isOnSale: true,
        newPrice: 0.25,

    },
    {
        id: 151,
        title: "Standard EPE Foam Underlayment",
        desc: "3.0mm",
        sku: "UL001",
        price: 0.25,
        category: "accessory",
        thickness: "3.0mm",
        package: "200sqft/roll",
        material: "EPE Foam",
        installation: "",
        country: "",
        image: [
            "../images/underlayment/ul001/epeUnderlay.jpg"
        ],
        isOnSale: true,
        newPrice: 0.17,

    },
    {
        id: 152,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 202",
        sku: "TFSPC202",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/202_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 153,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 205",
        sku: "TFSPC205",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/205_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 154,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 206",
        sku: "TFSPC206",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/206_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 155,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 208",
        sku: "TFSPC208",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/208_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 156,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 210",
        sku: "TFSPC210",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/210_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 157,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 211",
        sku: "TFSPC211",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/211_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 158,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 212",
        sku: "TFSPC212",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/212_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 159,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 215",
        sku: "TFSPC215",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/215_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 160,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 216",
        sku: "TFSPC216",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/216_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 161,
        title: "Toucan - SPC 2 Series",
        desc: "Colour: 253",
        sku: "TFSPC253",
        price: 3.49,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "11/64\" thick X 7-1/4\" wide X 48\" in length",
        package: "24.04sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "grey",
        brand: "toucan",
        country: "China",
        overview: details[11].content,
        image: [
            "../images/spc/toucan/spc2/253_A.jpg",
        ],
        isOnSale: true,
        newPrice: 2.59,
    },
    {
        id: 162,
        title: "Toucan Laminate TF6011",
        desc: "Colour: TF 6011",
        sku: "6011",
        price: 2.59,
        isOnSale: true,
        newPrice: 1.75,
        category: "laminate",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/clearance/tf6011.jpg"
        ],
    },
    {
        id: 163,
        title: "Toucan Laminate TF1122",
        desc: "Colour: TF 1122",
        sku: "1122",
        price: 2.49,
        isOnSale: true,
        newPrice: 1.65,
        category: "laminate",
        thickness: "12mm",
        width: "5\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 5\" wide X 48\" in length",
        package: "20.41sqft/carton",
        material: "Laminate",
        installation: "Floating",
        colour: "darkbrown",
        brand: "toucan",
        country: "China",
        overview: details[6].content,
        acrating: "AC4",
        image: [
            "../images/laminate/toucan/clearance/tf1122.jpg"
        ],
    },
    {
        id: 164,
        title: "Pravada - Le Soleil Collection",
        desc: "Colour: Mont Blanc 5376-6668",
        sku: "53766668",
        price: 8.99,
        isOnSale: true,
        newPrice: 7.79,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 87\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[12].content,
        image: [
            "../images/hardwood/Pravada/53766668.jpg"

        ],

    },
    {
        id: 165,
        title: "Pravada - Le Soleil Collection",
        desc: "Colour: Louvre 5376-5688",
        sku: "53765688",
        price: 8.99,
        isOnSale: true,
        newPrice: 7.79,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 87\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[12].content,
        image: [
            "../images/hardwood/Pravada/53765688.jpg"

        ],

    },
    {
        id: 166,
        title: "Pravada - Le Soleil Collection",
        desc: "Colour: Fleur 5376-3538",
        sku: "53763538",
        price: 8.99,
        isOnSale: true,
        newPrice: 7.79,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 87\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[12].content,
        image: [
            "../images/hardwood/Pravada/53763538.jpg"

        ],

    },
    {
        id: 167,
        title: "Pravada - Le Reve Collection",
        desc: "Colour: Ambiance 5337-6277",
        sku: "53376277",
        price: 8.99,
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X 36\" length",
        package: "15.54 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Glue Down",
        colour: "lightgrey",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[13].content,
        image: [
            "../images/hardwood/Pravada/53376277.jpg"

        ],

    },
    {
        id: 168,
        title: "Pravada - Le Reve Collection",
        desc: "Colour: Aurore 5332-2876",
        sku: "53322876",
        price: 8.99,
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X 36\" length",
        package: "15.54 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[13].content,
        image: [
            "../images/hardwood/Pravada/53322876.jpg"

        ],

    },
    {
        id: 169,
        title: "Pravada - Le Reve Collection",
        desc: "Colour: Beaute 5332-2328",
        sku: "53322328",
        price: 8.99,
        category: "hardwood",
        thickness: "14mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "9/16\" thick X 7-1/2\" wide X 36\" length",
        package: "15.54 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[13].content,
        image: [
            "../images/hardwood/Pravada/53322328.jpg"

        ],

    },
    {
        id: 170,
        title: "Pravada - Haute Couleur Collection",
        desc: "Colour: Nude Roche 3833-6833",
        sku: "38336833",
        price: 7.29,
        isOnSale: true,
        newPrice: 6.49,
        category: "hardwood",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X Random Length up to 72\"",
        package: "31.08 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[14].content,
        image: [
            "../images/hardwood/Pravada/38336833.jpg"

        ],
        isOnSale: true,
        newPrice: 5.99,

    },
    {
        id: 171,
        title: "Pravada - Haute Couleur Collection",
        desc: "Colour: Chiffon Drape 3833-2443",
        sku: "38332443",
        price: 7.29,
        isOnSale: true,
        newPrice: 6.49,
        category: "hardwood",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X Random Length up to 72\"",
        package: "31.08 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[14].content,
        image: [
            "../images/hardwood/Pravada/38332443.jpg"

        ],
        isOnSale: true,
        newPrice: 5.99,

    },
    {
        id: 171,
        title: "Pravada - Haute Couleur Collection",
        desc: "Colour: Crate 3288-2728",
        sku: "32882728",
        price: 7.29,
        isOnSale: true,
        newPrice: 6.49,
        category: "hardwood",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X Random Length up to 72\"",
        package: "31.08 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[14].content,
        image: [
            "../images/hardwood/Pravada/32882728.jpg"

        ],
        isOnSale: true,
        newPrice: 5.99,

    },
    {
        id: 172,
        title: "Pravada - Ferme Chic Collection",
        desc: "Colour: Clay 3288-2529",
        sku: "32882529",
        price: 7.29,
        isOnSale: true,
        newPrice: 6.49,
        category: "hardwood",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X Random Length up to 72\"",
        package: "31.08 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[14].content,
        image: [
            "../images/hardwood/Pravada/32882529.jpg"

        ],
        isOnSale: true,
        newPrice: 5.99,

    },
    
    {
        id: 173,
        title: "Pravada - Ferme Chic Collection",
        desc: "Colour: Aged Leather 3288-2453",
        sku: "32882453",
        price: 7.29,
        isOnSale: true,
        newPrice: 6.49,
        category: "hardwood",
        thickness: "12mm",
        width: "7-1/2\"",
        widthinterval: "177-203",
        dimension: "1/2\" thick X 7-1/2\" wide X Random Length up to 72\"",
        package: "31.08 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[14].content,
        image: [
            "../images/hardwood/Pravada/32882453.jpg"

        ],
        isOnSale: true,
        newPrice: 5.99,

    },
    {
        id: 174,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Moreau 2788-6673",
        sku: "27886673",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27886673.jpg"

        ],
    },
    {
        id: 175,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Matisse 2788-6284",
        sku: "27886284",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27886284.jpg"

        ],
    },
    {
        id: 176,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Lemaire 2788-5364",
        sku: "27885364",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27885364.jpg"

        ],
    },
    {
        id: 177,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Van Gogh 2788-4644",
        sku: "27884644",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27884644.jpg"

        ],
    },
    {
        id: 178,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Duvet 2788-3883",
        sku: "27883883",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27883883.jpg"

        ],
    },
    {
        id: 178,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Duval 2788-3882",
        sku: "27883882",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27883882.jpg"

        ],
    },
    {
        id: 179,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Francois 2788-3726",
        sku: "27883726",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27883726.jpg"

        ],
    },
    {
        id: 180,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Eiffel 2788-3433",
        sku: "27883433",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "darkbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27883433.jpg"

        ],
    },
    {
        id: 181,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Auguste 2788-2848",
        sku: "27882848",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27882848.jpg"

        ],
    },
    {
        id: 182,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Clouette 2788-2568",
        sku: "27882568",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27882568.jpg"

        ],
    },
    {
        id: 183,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Claude 2788-2528",
        sku: "27882528",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightgrey",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27882528.jpg"

        ],
    },
    {
        id: 183,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Claude 2788-2426",
        sku: "27882426",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27882426.jpg"

        ],
    },
    {
        id: 184,
        title: "Pravada - Artistique Collection",
        desc: "Colour: Claude 2788-2273",
        sku: "27882273",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "9-1/2\"",
        widthinterval: "228-253",
        dimension: "9/16\" thick X 9-1/2\" wide X Random Length up to 84\"",
        package: "34.10 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[15].content,
        image: [
            "../images/hardwood/Pravada/27882273.jpg"

        ],
    },
    {
        id: 184,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Palette 2268-7253",
        sku: "22687253",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22687253.jpg"

        ],
    },
    {
        id: 185,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Nouveau 2268-6688",
        sku: "22686688",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22686688.jpg"

        ],
    },
    {
        id: 186,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Monogram 2268-4726",
        sku: "22684726",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22684726.jpg"

        ],
    },
    {
        id: 187,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Monogram 2268-4363",
        sku: "22684363",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22684363.jpg"

        ],
    },
    {
        id: 188,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Easel 2268-3275",
        sku: "22683275",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22683275.jpg"

        ],
    },
    {
        id: 189,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Aura 2268-2872",
        sku: "22682872",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22682872.jpg"

        ],
    },
    {
        id: 190,
        title: "Pravada - Canvas Collection",
        desc: "Colour: Atelier 2268-2835",
        sku: "22682835",
        price: 8.99,
        isOnSale: true,
        newPrice: 8.49,
        category: "hardwood",
        thickness: "14mm",
        width: "8-1/2\"",
        widthinterval: "203-228",
        dimension: "9/16\" thick X 8-1/2\" wide X Random Length up to 84\"",
        package: "31.26 sqft/carton",
        material: "Engineered Hardwood",
        installation: "Tongue & Groove, Staple, Glue Down",
        colour: "lightbrown",
        species: "oak",
        brand: [
            "pravada"
        ],
        country: "China",
        warranty: "35 years residential, lifetime structural",
        overview: details[16].content,
        image: [
            "../images/hardwood/Pravada/22682835.jpg"

        ],
    },
];

export const bestSeller = [
    {
        id: 1,
        title: "Clivia SPC Floors",
        desc: "Colour: CL321 Bridal Veil Falls",
        sku: "CL321",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "lightbrown",
            "darkbrown"
        ],
        brand: "clivia",
        country: "China",
        overview: details[1].content,
        warranty: "lifetime residential, 10 years commercial",
        image: [
            "../images/spc/clivia/installed/cl321i.jpg",
            "../images/spc/clivia/full/cl321f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.79,

    },
    {
        id: 2,
        title: "Clivia SPC Floors",
        desc: "Colour: CL322 Cascade Creek",
        sku: "CL322",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: "grey",
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl322i.jpg",
            "../images/spc/clivia/full/cl322f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.79,

    },
    
    {
        id: 8,
        title: "Clivia SPC Floors",
        desc: "Colour: CL328 Sunshine Coast",
        sku: "CL328",
        price: 3.29,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "7-1/4\"",
        widthinterval: "177-203",
        dimension: "1/4\" thick X 7-1/4\" wide X 48\" in length",
        package: "19.22sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.3mm (12mil)",
        installation: "Floating",
        colour: [
            "white",
            "grey"
        ],
        brand: "clivia",
        country: "China",
        warranty: "lifetime residential, 10 years commercial",
        overview: details[1].content,
        image: [
            "../images/spc/clivia/installed/cl328i.jpg",
            "../images/spc/clivia/full/cl328f.jpg",
        ],
        isOnSale: true,
        newPrice: 2.79,

    },
    {
        id: 123,
        title: "Aquafix - Grand Cru",
        desc: "Colour: EC28952 Romanee",
        sku: "EC28952",
        price: 12345,
        category: "vinyl",
        thickness: "6.5mm",
        underlayment: "1.5mm ixpe",
        width: "9\"",
        widthinterval: "203-228",
        dimension: "1/4\" thick X 9\" wide X 71\" in length",
        package: "22.28sqft/carton",
        material: "SPC Luxury Vinyl",
        wearlayer: "0.5mm (20mil)",
        installation: "Floating",
        colour: "lightbrown",
        brand: "aquafix",
        country: "China",
        warranty: "lifetime residential, 15 years commercial",
        overview: details[10].content,
        image: [
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-feature-image-for-web-romanee2.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-single-panel-for-web-Romanee.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Grand-Cru-top-view-for-web-Romanee.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-1.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-2.jpg",
            "../images/spc/aquafix/grandcru/ec28952/Room-Scene-for-web-Romanee-3.jpg",
        ],

    },
]

export function getProduct(sku) {
    return products.find(
        (product) => product.sku === sku
    );
}


export function getFeatures(sku) {
    return features.find(
        (feature) => feature.sku === sku
    )
}

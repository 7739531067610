import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const FilterListToggle = ({ options, value, selectToggle,selectClick }) => {
  return (
    <ToggleButtonGroup
    color="primary"
    value={value}
    exclusive
    onChange={selectToggle}
    orientation="vertical"
    size="small"
    >
        {options.map(({ label, id, value }) => (
            <ToggleButton key={id} value={value} onClick={selectClick}>
                {label}
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};
export default FilterListToggle;
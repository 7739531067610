import React from 'react';
import ListItem from './ListItem';

const List = ({ list }) => (
    <>
        
            <div className="row">
                {list.map((item) => (
                    <ListItem key={item.id} item={item} />
                ))}
            </div>

    </>
)

// function PaginatedItems({ itemsPerPage, list }) {
//     // We start with an empty list of items.
//     const [currentItems, setCurrentItems] = useState(list);
//     const [pageCount, setPageCount] = useState(0);
//     // Here we use item offsets; we could also use page offsets
//     // following the API or data you're working with.
//     const [itemOffset, setItemOffset] = useState(0);

//     useEffect(() => {
//         // Fetch items from another resources.
//         const endOffset = itemOffset + itemsPerPage;
//         console.log(`Loading items from ${itemOffset} to ${endOffset}`);
//         setCurrentItems(list.slice(itemOffset, endOffset));
//         setPageCount(Math.ceil(list.length / itemsPerPage));
//         console.log(list);
//         console.log(currentItems);
//     }, [itemOffset, itemsPerPage]);

//     // Invoke when user click to request another page.
//     const handlePageClick = (event) => {
//         const newOffset = (event.selected * itemsPerPage) % list.length;
//         console.log(
//             `User requested page number ${event.selected}, which is offset ${newOffset}`
//         );
//         setItemOffset(newOffset);
//     };

//     return (
//         <>
//             <List currentItems={currentItems} />
//             <ReactPaginate
//                 breakLabel="..."
//                 nextLabel="next >"
//                 onPageChange={handlePageClick}
//                 pageRangeDisplayed={5}
//                 pageCount={pageCount}
//                 previousLabel="< previous"
//                 renderOnZeroPageCount={null}
//             />
//         </>
//     );
// }



export default List;
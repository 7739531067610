import { React, useState, useCallback } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ImageViewer from "react-simple-image-viewer";
import "./Slider.css";

export default function Slider({ images }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <Carousel
        infiniteLoop
        autoPlay
        interval="5000"
        onClickItem={(index) => openImageViewer(index)}
      >
        {images.map((image, index) => {
          return (
            <div className="image">
              <img
                className="d-block w-100"
                src={image}
                key={index}
                alt="product"
              />
            </div>
          );
        })}
      </Carousel>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>

    // =========================Previous working image loader========================

    // <div >

    //     <Carousel
    //      infiniteLoop
    //      autoPlay
    //      interval="5000"
    //      >
    //         {images.map(image => {
    //             return (
    //                 <div className="image">
    //                     <img className='d-block w-100' src={image} alt="product image" />
    //                 </div>
    //             )
    //         })}

    //     </Carousel>

    // </div>
  );
}

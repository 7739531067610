import React from 'react';
import './tabOverview.css';

const TabOverview = ({overview}) => {
    
  return (
    <div className='tab-overview'>
        {overview}
    </div>
  )
}

export default TabOverview
import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className="section-footer">
            <div className="container">
                <div>
                    <h2>Horizon Floors</h2>
                    <a href="https://www.facebook.com/horizonfloorsbc">
                        <img width="50" src="../images/icon/facebook.svg" alt="" />
                    </a>
                    <a href="http://twitter.com">
                        <img width="50" src="../images/icon/twitter.svg" alt="" />
                    </a>
                    <a className='text-light' href="http://youtube.com">
                        <img src="../images/icon/youtube.svg" alt="" />
                    </a>
                </div>
                <div>
                    <h3>Shop Our Store</h3>
                    <ul>
                        <li><Link to="/laminate">Laminate Floors</Link></li>
                        <li><Link to="/vinyl">Vinyl Floors</Link></li>
                        <li><Link to="/hardwood">Engineered Hardwood</Link></li>
                    </ul>
                </div>
                <div>
                    <h3>Other Info</h3>
                    <ul>
                        <li><Link to="/return">Return Policy</Link></li>
                    </ul>
                </div>
                
                <div>
                    <h3>Address</h3>
                    <p>6660 Graybar Rd #115, Richmond, BC V6W 1H9</p>
                    <p>778-877-7217</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React from "react";
import FilterListToggle from "./common/FilterListToggle";
import {
  categoryList,
  thicknessList,
  widthList,
  colourList,
  brandList,
} from "../constants/Data";
import RadioToggle from "./common/RadioToggle";
import "./filterPanel.css";

const FilterPanel = ({
  selectedCategory,
  selectCategory,
  selectClickCategory,
  selectedThickness,
  selectThickness,
  selectedWidth,
  selectWidth,
  selectedColour,
  selectColour,
  selectedBrand,
  selectBrand,
  selectedSpecies,
  selectSpecies,
  thicknessOptions,
  widthOptions,
  colourOptions,
  brandOptions,
  speciesOptions,
  isSpecies,
  isBrand,
  isColour,
  isWidth,
  isThickness,
}) => {
  return (
    <>
    
      <nav className="navbar navbar-expand-md navbar-light" id="filterNav">
      <h2 className="label">FILTERS</h2>
        <button
          className="navbar-toggler"
          id="toggleButton"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#filterPanelToggle"
          aria-controls="filterPanelToggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse filter-list"
          id="filterPanelToggle"
        >
          <div className="input-group row mt-2">
            <h3 className="label">Category</h3>
            <FilterListToggle
              options={categoryList}
              value={selectedCategory}
              selectToggle={selectCategory}
              selectClick={selectClickCategory}
            />
          </div>

          {isThickness ? (
            <div className="input-group row mt-2">
              <h3 className="label">Thickness</h3>
              <div className="mx-2">
                <RadioToggle
                  options={thicknessOptions}
                  value={selectedThickness}
                  selectToggle={selectThickness}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {isWidth ? (
            <div className="input-group row">
              <h3 className="label">Width</h3>
              <div className="mx-2">
                <RadioToggle
                  options={widthOptions}
                  value={selectedWidth}
                  selectToggle={selectWidth}
                />
              </div>
            </div>
          ) : (
            <div> </div>
          )}

          {isColour ? (
            <div className="input-group row">
              <h3 className="label">Colour</h3>
              <div className="mx-2">
                <RadioToggle
                  options={colourOptions}
                  value={selectedColour}
                  selectToggle={selectColour}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {isBrand ? (
            <div className="input-group row">
              <h3 className="label">Brand</h3>
              <div className="mx-2">
                <RadioToggle
                  options={brandOptions}
                  value={selectedBrand}
                  selectToggle={selectBrand}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {isSpecies ? (
            <div className="input-group row">
              <h3 className="label">Species</h3>
              <div className="mx-2">
                <RadioToggle
                  options={speciesOptions}
                  value={selectedSpecies}
                  selectToggle={selectSpecies}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </nav>
    </>
  );
};

// const FilterPanelsssss = ({
//   selectedCategory,
//   selectCategory,
//   selectedThickness,
//   selectThickness,
//   selectedWidth,
//   selectWidth,
//   selectedColour,
//   selectColour,
//   selectedBrand,
//   selectBrand,
// }) => (
//   <div className="filterPanel">
//     <div className="input-group row mt-2">
//       <h3 className="label">Category</h3>
//       <FilterListToggle
//         options={categoryList}
//         value={selectedCategory}
//         selectToggle={selectCategory}
//       />
//     </div>
//     <div className="input-group row mt-2">
//       <h3 className="label">Thickness</h3>
//       <div className="mx-2">
//         <RadioToggle
//           options={thicknessList}
//           value={selectedThickness}
//           selectToggle={selectThickness}
//         />
//       </div>
//     </div>
//     <div className="input-group row">
//       <h3 className="label">Width</h3>
//       <div className="mx-2">
//         <RadioToggle
//           options={widthList}
//           value={selectedWidth}
//           selectToggle={selectWidth}
//         />
//       </div>
//     </div>
//     <div className="input-group row">
//       <h3 className="label">Colour</h3>
//       <div className="mx-2">
//         <RadioToggle
//           options={colourList}
//           value={selectedColour}
//           selectToggle={selectColour}
//         />
//       </div>
//     </div>
//     <div className="input-group row">
//       <h3 className="label">Brand</h3>
//       <div className="mx-2">
//         <RadioToggle
//           options={brandList}
//           value={selectedBrand}
//           selectToggle={selectBrand}
//         />
//       </div>
//     </div>
//   </div>
// );

export default FilterPanel;

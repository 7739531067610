import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProduct } from '../constants/Data';
import Slider from '../Components/common/Slider/Slider';
import './product.css';
import { Tabs, Tab } from 'react-bootstrap';
import TabOverview from '../Components/Product/TabOverview';
import TabSpec from '../Components/Product/TabSpec';
import BreadCrumb from '../Components/BreadCrumb';
import BoxCalculator from '../Components/BoxCalculator';
import Helmet from 'react-helmet';


const Product = () => {

  let params = useParams();
  let product = getProduct(params.productSku);
  // let features = getFeatures(params.productSku);
  const [key, setKey] = useState('overview');
  const colourName = product.desc.replace('Colour:','');
  
  const stringedOverview = colourName + ' is ' + product.material + ' flooring by ' + product.brand + ' with ' + product.installation + ' installation method. The dimension of the plank is ' + product.dimension + '.';
  // const canonical = "/" + params;
  
  return (

    <>
      <Helmet>
        <title>{colourName} - Horizon Floors</title>
        <meta name='description' content={stringedOverview} />
        <link rel="canonical" href="/products" />
      </Helmet>
      <div className="d-flex justify-content-center">
        <main className="container-md mx-2">
          <BreadCrumb productCategory={product.category} productSku={params.productSku} />
          <div className="row">
            <div className="col-md-4">

              <Slider images={product.image} />


            </div>

            <div className="col-md-8">
              <div className="mx-4">
                <h2>{product.title}</h2>
                <h5>{product.desc}</h5>
                <h3 className='text-right'>{product.isOnSale ?
                  <p>Price: <span className='priceCrossed'>${product.price}</span><span className='newPrice'>Sale Price: ${product.newPrice}</span>/SQFT</p> :
                  product.price === 12345 ?
                    <p>Call For Pricing</p> :
                    <p>Price: ${product.price}/SQFT</p>}</h3>
                <h3 className='mt-2'>Details</h3>
                <table className='table'>
                  <tbody >
                    {product.dimension ?
                      <tr>
                        <th scope='row'>Dimension:</th>
                        <td>{product.dimension}</td>
                      </tr> :
                      ""}

                    <tr>
                      <th scope='row'>Package:</th>
                      <td>{product.package}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Material:</th>
                      <td>{product.material}</td>
                    </tr>
                    {product.installation ?
                      <tr>
                        <th scope='row'>Installation Method:</th>
                        <td>{product.installation}</td>
                      </tr> :
                      ""}


                  </tbody>
                </table>

              </div>
              <BoxCalculator />
            </div>

          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="overview" title="Overview">
              <TabOverview overview={product.overview} />
            </Tab>
            <Tab eventKey="spec" title="Specifications">
              <TabSpec
                thickness={product.thickness}
                width={product.width}
                wearlayer={product.wearlayer}
                acrating={product.acrating}
                underlayment={product.underlayment}
                installation={product.installation}
                packaging={product.package}
                country={product.country}
                warranty={product.warranty}
              />
            </Tab>


          </Tabs>
        </main>

      </div>
    </>
  )
}

export default Product;



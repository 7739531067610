import React, { useEffect, useState } from "react";
import {
  thicknessLaminate,
  widthLaminate,
  colourLaminate,
  brandLaminate,
  products,
} from "../constants/Data";
import { useNavigate } from "react-router-dom";
import FilterPanel from "../Components/FilterPanel";
import List from "../Components/List";
import EmptyView from "../Components/common/EmptyView";
import PagePaginate from "../Components/common/PagePaginate";
import BreadCrumb from "../Components/BreadCrumb";
import Sort from "../Components/Sort";
import SearchForm from "../Components/SearchForm";
import Helmet from "react-helmet";

const Laminate = () => {
  const [selectedCategory, setSelectedCategory] = useState("laminate");

  const [selectedThickness, setSelectedThickness] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState(null);
  const [selectedColour, setSelectedColour] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  // Sort State
  const [selectedSort, setSelectedSort] = useState("");

  // Search State
  const [searchedTerm, setSearchedTerm] = useState("");

  const [list, setList] = useState(products);
  const [resultsFound, setResultsFound] = useState(true);

  const closeFilter = document.getElementById("toggleButton");
  // options filter

  const [thicknessOptions] = useState(thicknessLaminate);
  const [widthOptions] = useState(widthLaminate);
  const [colourOptions] = useState(colourLaminate);
  const [brandOptions] = useState(brandLaminate);

  // handle filter

  const navigate = useNavigate();

  const categoryReset = () => {
    setSelectedThickness(null);
    setSelectedWidth(null);
    setSelectedColour(null);
    setSelectedBrand(null);
    setSearchedTerm("");
  };

  const handleSelectCategory = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedCategory(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      navigate(`/${value}`);
      categoryReset();
      localStorage.clear();
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
    }
  };
  const handleSelectClickCategory = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedCategory(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      categoryReset();
      localStorage.clear();
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
    }
  };

  const handleSelectThickness = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedThickness(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
    }
  };

  const handleSelectWidth = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedWidth(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
    }
  };
  const handleSelectColour = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedColour(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
      console.log(value);
    }
  };

  const handleSelectBrand = (event, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedBrand(value);
      setCurrentPage(1);
      window.scrollTo(0, 0);
      if (window.innerWidth < 767) {
        closeFilter.click();
      }
    }
  };

  // Sort

  const handleSelectSort = (e, value) => {
    if (!value) {
      return null;
    } else {
      setSelectedSort(e.target.value);
      console.log(e.target.value);
    }
  };
  const cleanData = (data) => {
    const cleanedData = data.map((el) => {
      if (isNaN(el.price)) {
        el.price = 12345;
      }
      return el;
    });
    return cleanedData;
  };

  const sortedList = list.sort((a, b) => {
    if (selectedSort === "pricelowest") {
      return a.newPrice - b.newPrice || a.price - b.price;
    } else if (selectedSort === "pricehighest") {
      return b.newPrice - a.newPrice || b.price - a.price;
    }
  });

  // Search

  const handleInputValue = (event) => {
    setSearchedTerm(event.target.value);
  };

  const handleSearchTerm = (event) => {
    event.preventDefault();

    let searchedList = products;

    if (searchedTerm) {
      searchedList = searchedList.filter((val) => {
        let search = searchedTerm;
        if (!search) return true;
        if (
          val.title
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, "")) ||
          val.desc
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, "")) ||
          val.sku
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, "")) ||
          val.material
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, "")) ||
          val.installation
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, "")) ||
          val.country
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(search.toLowerCase().replace(/\s+/g, ""))
        ) {
          return val;
        }
      });
    }
    setList(searchedList);
    !searchedList.length ? setResultsFound(false) : setResultsFound(true);
  };

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(18);

  // reset button

  const clearState = () => {
    setSelectedCategory(null);
    setSelectedThickness(null);
    setSelectedWidth(null);
    setSelectedColour(null);
    setSelectedBrand(null);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const applyFilters = () => {
      let updatedList = cleanData(products);

      // Category Filter
      if (selectedCategory) {
        updatedList = updatedList.filter(
          (item) => item.category === selectedCategory
        );
      }

      // Thickness Filter
      if (selectedThickness) {
        updatedList = updatedList.filter(
          (item) => item.thickness === selectedThickness
        );
      }
      if (selectedWidth) {
        updatedList = updatedList.filter(
          (item) => item.widthinterval === selectedWidth
        );
      }
      if (selectedColour) {
        updatedList = updatedList.filter(
          (item) =>
            item.colour === selectedColour ||
            item.colour[0] === selectedColour ||
            item.colour[1] === selectedColour
        );
      }
      if (selectedBrand) {
        updatedList = updatedList.filter(
          (item) => item.brand === selectedBrand
        );
      }

      setList(updatedList);
      !updatedList.length ? setResultsFound(false) : setResultsFound(true);
    };
    applyFilters();
  }, [
    selectedCategory,
    selectedThickness,
    selectedWidth,
    selectedColour,
    selectedBrand,
  ]);

  React.useEffect(() => {
    const thickness = localStorage.getItem("thickness-selection");
    const width = localStorage.getItem("width-selection");
    const colour = localStorage.getItem("colour-selection");
    const brand = localStorage.getItem("brand-selection");
    const sort = localStorage.getItem("sort-selection");

    // if (data) {
    //   setList(JSON.parse(data));
    // }

    // if (category) {
    //   setSelectedCategory(JSON.parse(category));
    // }
    if (thickness) {
      setSelectedThickness(JSON.parse(thickness));
    }
    if (width) {
      setSelectedWidth(JSON.parse(width));
    }
    if (colour) {
      setSelectedColour(JSON.parse(colour));
    }
    if (brand) {
      setSelectedBrand(JSON.parse(brand));
    }
    if (sort) {
      setSelectedSort(JSON.parse(sort));
    }
  }, []);

  React.useEffect(() => {
    // localStorage.setItem("filter-selection", JSON.stringify(list));
    // localStorage.setItem("category-selection", JSON.stringify(selectedCategory));
    localStorage.setItem(
      "thickness-selection",
      JSON.stringify(selectedThickness)
    );
    localStorage.setItem("width-selection", JSON.stringify(selectedWidth));
    localStorage.setItem("colour-selection", JSON.stringify(selectedColour));
    localStorage.setItem("brand-selection", JSON.stringify(selectedBrand));
    localStorage.setItem("sort-selection", JSON.stringify(selectedSort));
  });

  // Get current List

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedList.slice(indexOfFirstItem, indexOfLastItem);

  // CHange page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // console.log(currentItems);

  return (
    <>
      <Helmet>
        <title>Laminate - Horizon Floors</title>
        <meta name="description" content="Explore Laminate Floors" />
        <link rel="canonical" href="/laminate" />
      </Helmet>
      <BreadCrumb />

      <div className="col-md-3 mb-5" id="categoryAccordion">
        <button className="mx-3" onClick={clearState}>
          Show All Products
        </button>
        <FilterPanel
          className="row"
          selectedCategory={selectedCategory}
          selectCategory={handleSelectCategory}
          selectClickCategory={handleSelectClickCategory}
          selectedThickness={selectedThickness}
          selectThickness={handleSelectThickness}
          selectedWidth={selectedWidth}
          selectWidth={handleSelectWidth}
          selectedColour={selectedColour}
          selectColour={handleSelectColour}
          selectedBrand={selectedBrand}
          selectBrand={handleSelectBrand}
          thicknessOptions={thicknessOptions}
          widthOptions={widthOptions}
          colourOptions={colourOptions}
          brandOptions={brandOptions}
          isSpecies={false}
          isBrand={true}
          isColour={true}
          isWidth={true}
          isThickness={true}
        />
      </div>
      {/* List & Empty View */}
      <div className="col-md-9">
        <SearchForm
          searchedTerm={searchedTerm}
          searchTerm={handleSearchTerm}
          inputValue={handleInputValue}
        />
        <Sort selectedSort={selectedSort} selectSort={handleSelectSort} />
        <h4 className="row ms-auto mb-3">
          {selectedCategory} &gt;
          {selectedThickness} &gt;
          {selectedWidth} &gt;
          {selectedColour} &gt;
          {selectedBrand} &gt;
          {searchedTerm} &gt; Total {list.length} Product(s)
        </h4>

        {resultsFound ? <List list={currentItems} /> : <EmptyView />}

        <div className="row">
          <PagePaginate
            itemsPerPage={itemsPerPage}
            totalItems={list.length}
            paginate={paginate}
          />
        </div>
      </div>
    </>
  );
};

export default Laminate;
